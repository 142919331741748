export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: number | undefined;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

export const formatMoney = (amount: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
}

export const formatDateTime = () => {
  const date = new Date().toLocaleDateString('en-US', {
    timeZone: 'EST',
    month: "short",
    day: "numeric",
  });

  const time = new Date().toLocaleTimeString('en-US', {
    timeZone: 'EST',
    hour: "numeric",
    minute: "numeric"
  });

  return `${date} at ${time}`;
};

type Times = Record<'opens' | 'closes', number>;

export const getStoreHours = () => {
  const hours: Times[] = [
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 }
  ];

  return hours;
};

export const getFlavors = () => {
  return [
    'Birthday + Sprinkle Cream Cheese',
    'Chocolate Chip + Dark Choc Fudge',
    'Red Velvet + Cream Cheese',
    'Dark Fudge + Vanilla',
    'Snickerdoodle + Cinnamon Brown Sugar',
    'Toasted Coconut + Saleted Caramel',
    'Cookies + Cream',
    'Lemon Sugar + Lemon Pudding',
    'Pumpkin Pie Cheesecake',
    'Double Dark Chocolate + Peppermint',
    'Oatmeal + Gingersnap',
    'S\'more + Toasted Marshmallow'
  ];
};

export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-') // separator
}

import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';

Alpine.plugin(mask)

window.Alpine = Alpine;
window.initializeAutocomplete = () => {
  const event = new Event('initializeAutocomplete');
  document.dispatchEvent(event);
}

import './scripts/product-variable';
import './scripts/product-grouped';
import './scripts/checkout';
import './scripts/selector-time';

import './styles/cart.scss';
import './styles/product.scss';
import './styles/checkout.scss';
import 'vanillajs-datepicker/sass/datepicker.scss';

const { cart } = window.modularink_local_delivery;
const html = document.querySelector('html');

if ( html ) {
  html.setAttribute( 'x-data', 'store' );
  html.setAttribute( 'x-on:set-cart-count.window', 'cart.count = $event.detail' );

  Alpine.data( 'store', () => ( {
    cart: {
      count: cart.count
    }
  } ) );

  Alpine.start();
}

import Alpine, { AlpineComponent } from 'alpinejs';

import { formatMoney, getFlavors, slugify } from './utils';

const { ajax_url } = window.modularink_local_delivery;
const form = document.querySelector('body.product-template-default .product_cat-48-pack form.cart');

type Product = AlpineComponent<{
  loading: boolean;
  message: string,
  total: number;
  flavors: string[];
  quantities: {
    [key: string]: number;
  };
  init: () => void;
  handleChange: (id: string, type: string) => void;
  formatMoney: (amount: number) => string;
  slugify: (...args: (string | number)[]) => string;
  handleSubmit: () => void;
}>;

if(form) {
  form.setAttribute('x-data', 'product');
  form.setAttribute('x-on:submit.prevent', 'handleSubmit');
  const button = form.querySelector('button[type="submit"]');

  if(button) {
    button.setAttribute('x-ref', 'button');
    button.setAttribute('x-bind:disabled', 'total !== 48');
    button.innerHTML = '';
    button.setAttribute('x-text', 'loading ? "Adding to Cart" : "Add to Cart"');
  }

  Alpine.data('product', (): Product => ({
    loading: false,
    message: 'Please add 48 selections to your box to add it to your cart.',
    total: 0,
    flavors: getFlavors(),
    quantities: {},

    init() {
      if ( this.flavors.length ) {
        this.flavors.map( ( flavor ) => {
          this.quantities[`${slugify(flavor)}`] = 0;
        } );
      }

      this.$watch( 'total', ( value: number ) => {
        if ( value === 48 ) {
          this.message = 'You\'re all set! Add your box to your cart.';
          return;
        }

        this.message = `Please add ${48 - value}${value === 0 ? '' : ' more'} selections to your box to add it to your cart.`;
      });
    },

    handleChange( id: string, type: string ) {

      if ( ! id ) {
        return;
      }

      if ( this.total >= 48 && type === 'increment' ) {
        return;
      }

      if ( ( this.total <= 0 || this.quantities[id] <= 0 ) && type === 'decrement' ) {
        return;
      }

      this.quantities[id] = type === 'increment' ? this.quantities[id] + 6 : this.quantities[id] - 6;
      this.total = Object.values( this.quantities ).reduce( ( total, quantity ) => total + quantity, 0 );
    },

    formatMoney(amount: number) {
      return formatMoney(amount);
    },

    slugify(...args: (string | number)[]) {
      return slugify(...args);
    },

    handleSubmit() {
      this.loading = true;
      const data = form ? window.jQuery(form).serialize() : null;

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'product_add_to_cart',
          item: data,
          flavors: this.quantities,
        },
        success: (response) => {
          this.loading = false;

          if (!response.success) {
            return;
          }

          this.$dispatch('set-cart-count', response.data.cart_count);
        },
      });
    },
  }));
}

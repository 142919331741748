/* global google */
import Alpine, { AlpineComponent } from 'alpinejs';

import { formatMoney } from './utils';

type State = AlpineComponent<{
  product_id: string | null;
  variation_id: string | null;
  quantity: number;
  price: string;
  address: string | null;
  result: string | null;
  gift: string;
  notecard: boolean;
  loading: boolean;
  selected: any;
  showAddress: boolean;
  validateAddress: () => void;
  updatePrice: () => void;
}>;

type Variation = {
  variation_id: string;
  display_price: number;
};

const { ajax_url } = window.modularink_local_delivery;
const form = document.querySelector('body.product-template-default form.variations_form.cart');

if(form) {
  const product_id: HTMLInputElement | null = document.querySelector('input[name="product_id"]');
  const variation_id: HTMLInputElement | null = document.querySelector('input[name="variation_id"]');
  const quantity: HTMLInputElement | null = document.querySelector('input[name="quantity"]');
  const button = document.querySelector('button[type="submit"].single_add_to_cart_button');
  const addressInput = document.getElementById('delivery-address');

  const json = form ? form.getAttribute('data-product_variations') : null;
  const variations = json ? JSON.parse(json) : [];

  if(product_id) {
    product_id.setAttribute('x-ref', 'product_id');
    product_id.setAttribute('x-model', 'product_id');
  }

  if(variation_id) {
    variation_id.setAttribute('x-ref', 'variation_id');
    variation_id.setAttribute('x-model', 'variation_id');
  }

  if(quantity) {
    quantity.setAttribute('x-ref', 'quantity');
    quantity.setAttribute('x-model', 'quantity');
  }

  if(button) {
    button.setAttribute('x-ref', 'button');
    button.innerHTML = '';
    button.setAttribute('x-text', 'loading ? "Adding to Cart" : "Add to Cart"');
  }

  if(form) {
    form.setAttribute('x-data', 'product');
    form.setAttribute('x-on:submit.prevent', 'handleSubmit');
  }

  Alpine.data('product', () => ({
    loading: false,
    product_id: null,
    variation_id: null,
    quantity: 1,
    price: '0',
    address: null,
    result: null,
    gift: 'no',
    notecard: false,
    selected: null,
    showAddress: false,

    init() {
      this.product_id = product_id?.value || null;
      this.variation_id = variation_id?.value || null;
      this.quantity = quantity?.value ? parseInt(quantity.value, 10) : 1;

      this.$watch('address', () => {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInput, { types: ['geocode'] });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          this.address = place.formatted_address;
          this.validateAddress();
        });
      });

      this.$watch('variation_id', () => {
        this.updatePrice();
      });

      this.$watch('quantity', () => {
        this.updatePrice();
      });

      window.jQuery('.single_variation_wrap').on('show_variation', (_event, variation) => {
        this.variation_id = variation.variation_id;
      });
    },

    updatePrice() {
      this.selected = variations.find((variation: Variation) => variation.variation_id === this.variation_id);
      this.price = `${formatMoney((this.selected ? this.selected.display_price : 0) * this.quantity)}`;
    },

    handleSubmit() {
      this.loading = true;
      const data = form ? window.jQuery(form).serialize() : null;

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'product_add_to_cart',
          item: data,
        },
        success: (response) => {
          this.loading = false;

          if (!response.success) {
            return;
          }

          const button = document.querySelector('button[type="submit"].single_add_to_cart_button');
          button?.setAttribute('x-text', '"Added to Cart!"');

          window.setTimeout(() => {
            button?.setAttribute('x-text', '"Add to Cart"');
          }, 5000);

          this.$dispatch('set-cart-count', response.data.cart_count);
        },
      });
    },

    toggleAddress() {
      this.showAddress = !this.showAddress;
    },

    validateAddress() {
      this.result = null;

      if (!this.address) {
        this.result = 'No address provided!';
        return;
      }

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'product_check_address',
          address: this.address,
        },
        success: (response) => {

          if (!response.success) {
            this.result =
              'Local delivery is not available for this address, but you can still place your order for shipping.';
            return;
          }

          this.result = 'Local delivery is available for this address!';
        },
      });
    },
  } as State));
}

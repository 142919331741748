/* global google */
import Alpine, { AlpineComponent } from 'alpinejs';
import { DateTime } from 'luxon';
import Datepicker from 'vanillajs-datepicker/Datepicker';

import { formatMoney, getStoreHours } from './utils';
import { attachModels, attachMethods } from './checkout-form';
import { parseAddress } from './places';

const { ajax_url, nationwide } = window.modularink_local_delivery;
const checkout = document.querySelector('.woocommerce-checkout.woocommerce-page .woocommerce');
const form = document.querySelector('form.woocommerce-checkout');
const shippingAddressInput = document.getElementById('shipping_address_1');
const billingAddressInput = document.getElementById('billing_address_1');

type Address = {
  address_1: string;
  address_2: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
};

type Rate = {
  id: string;
  method: string;
  instance: string;
  label: string;
  description: string;
  delivery: {
    time: string;
    transit: string;
  };
  cost: string;
};

export type Checkout = AlpineComponent<{
  now: DateTime;
  datepicker: {
    element: HTMLInputElement | null;
    options: any;
    instance: Datepicker | null;
  };
  loading: boolean;
  error: string | null;
  message: string | null;
  step: string;
  notes: string;
  is_open: boolean;
  address: {
    delivery: string;
    nationwide: string;
    billing: string;
    result: string;
  };
  billing: Address & {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  shipping: Address & {
    first_name: string;
    last_name: string;
    phone: string;
  };
  fulfillment: {
    id: string;
    method: string | null;
    location: string | null;
    rates: Rate[];
    filtered: Rate[];
  };
  scheduling: {
    type: string;
    date: string | null;
    min: string;
    max: string | null;
    hour: number;
    minute: number;
    ampm: 'am' | 'pm';
    disabled_dates: any;
  };
  fees: {
    id: string;
    name: string;
    amount: number;
  }[],
  totals: {
    subtotal: number | null;
    shipping_total: number | null;
    fee_total: number | null;
    discount_total: number | null;
    total_tax: number | null;
    total: number | null;
  };
  available_rates: Record<string, Rate[]>,
  shipping_notice: string | null,
  excluded_delivery_dates: string[][],
  order_update_queue: number | undefined,
  catch_shipping_changes: boolean,
  order_updating: boolean,
  initialized: boolean,
  nationwide: string,
  init: () => void;
  showScheduling: () => boolean;
  isOpen: () => boolean;
  isPickup: () => boolean;
  isDelivery: () => boolean;
  isNationwide: () => boolean;
  formatMoney: (amount: number) => string;
  formatAddress: (address: Address) => string;
  validateOrder: (step: string) => void;
  getSelectedRate: () => void;
  updateOrderTotals: () => void;
  updateOrderReview: ( fulfillmentChanged?: boolean, block?: boolean ) => void;
  recreateWooShippingMethodInputs: () => void;
  fetchFulfillmentRates: () => void;
  validateRequiredFields: ( type: 'billing' | 'shipping' ) => void;
  handleStepSelection: (step: string) => void;
  handleFulfillmentSelection: (id: string) => void;
  handleSchedulingTypeSelection: (type: string) => void;
  handleSchedulingDateSelection: (date: string) => void;
  handleSchedulingTimeSelection: (time: string) => void;
  handleServiceSelection: (id: string) => void;
}>;

const storeHours = getStoreHours();

// const currentTime = () => DateTime.local().setZone('America/Chicago').toLocaleString(DateTime.TIME_24_WITH_SECONDS);

if ( checkout && form ) {
  checkout.setAttribute('x-data', 'checkout');
  checkout.setAttribute('x-bind:data-checkout-step', 'step');
  checkout.setAttribute('x-bind:data-checkout-fulfillment', 'fulfillment.id');
  attachModels();

  Alpine.data('checkout', (): Checkout => ({
    now: DateTime.local().setZone('America/Chicago'),
    datepicker: {
      element: null,
      options: null,
      instance: null,
    },
    loading: false,
    error: null,
    message: null,
    step: 'fulfillment',
    notes: '',
    is_open: true,
    address: {
      delivery: '',
      nationwide: '',
      billing: '',
      result: '',
    },
    billing: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      state: '',
      country: 'US',
    },
    shipping: {
      first_name: '',
      last_name: '',
      phone: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      state: '',
      country: 'US',
    },
    fulfillment: {
      id: 'pickup',
      method: null,
      location: null,
      rates : [],
      filtered: [],
    },
    scheduling: {
      type: 'asap',
      date: null,
      min: DateTime.local().setZone('America/Chicago').toFormat('MM-dd-yyyy'),
      max: null,
      hour: 7,
      minute: 0,
      ampm: 'am',
      disabled_dates: [],
    },
    fees: [],
    totals: {
      subtotal: null,
      shipping_total: null,
      fee_total: null,
      discount_total: null,
      total_tax: null,
      total: null,
    },
    available_rates: {},
    shipping_notice: '',
    excluded_delivery_dates: [],
    order_update_queue: undefined,
    catch_shipping_changes: false,
    order_updating: false,
    initialized: false,
    nationwide,

    init() {

      window.jQuery( document.body ).off( 'update_checkout' );

      const now = DateTime.local().setZone('America/Chicago');

      this.now = now;

      // Use the preselected shipping method, if any
      const preselectedMethod: string = ( window.jQuery( 'input[type="radio"][name="shipping_method[0]"]:checked' ).val() || window.jQuery( 'input[type="hidden"][name="shipping_method[0]"]' ).val() || '' ).toString();

      if ( preselectedMethod ) {
        if ( preselectedMethod.includes( 'pickup' ) ) {
          this.fulfillment.id = 'pickup';
        } else if ( preselectedMethod.includes( 'nationwide' ) ) {
          this.fulfillment.id = 'nationwide';
        } else {
          this.fulfillment.id = 'delivery';
        }

        this.fulfillment.method = preselectedMethod;
      }

      this.datepicker.element = document.querySelector('#scheduling-date') as HTMLInputElement;

      if ( this.isOpen() === false ) {
        this.is_open = false;
        this.scheduling.type = 'later';
      }

      if ( now.hour > storeHours[now.weekday - 1].closes - 1 ) {
        this.scheduling.min = now.plus({ days: 1 }).toFormat('MM-dd-yyyy');
      } else {
        this.scheduling.min = now.toFormat('MM-dd-yyyy');
      }

      this.datepicker.options = { format: 'mm-dd-yyyy', minDate: this.scheduling.min };
      this.datepicker.instance = new Datepicker( this.datepicker.element, this.datepicker.options );

      this.datepicker.element.addEventListener( 'changeDate', (e) => {
        const { date } = ( e as CustomEvent ).detail;
        this.scheduling.date =  ! date ? null : `${( date.getMonth() + 1 ).toString().padStart( 2, '0' )}-${date.getDate().toString().padStart( 2, '0')}-${date.getFullYear()}`;
      } );

      window.jQuery( document ).ajaxComplete( () => {
        window.jQuery( 'html, body' ).stop();
      } );

      window.jQuery(document.body).on( 'update_checkout', () => {

      });

      window.jQuery(document.body).on( 'updated_checkout', () => {
        this.recreateWooShippingMethodInputs();
        attachMethods();
      });

      document.addEventListener( 'scheduling-time-changed', ( e ) => {
        const { hour, minute, ampm } = ( e as CustomEvent ).detail;

        this.scheduling.hour = hour;
        this.scheduling.minute = minute;
        this.scheduling.ampm = ampm;
      } );

      this.$watch( 'scheduling.type', () => {
        this.validateRequiredFields( 'shipping' );
      } );

      this.$watch('scheduling.date', (value: string) => {

        const now = DateTime.local().setZone('America/Chicago');

        this.$dispatch('scheduling-date-changed', value );

        if ( ! value || ! /^\d{2}-\d{2}-\d{4}$/.test( value ) ) {
          this.validateRequiredFields( 'shipping' );
          return;
        }

        // DateTime from shop timezone
        const date = DateTime.fromFormat( value, 'MM-dd-yyyy', { zone: 'America/Chicago' } );

        if ( this.fulfillment.rates.length ) {

          this.fulfillment.filtered = this.fulfillment.rates.filter( rate => {

            if ( ! rate.delivery?.transit ) return false;

            const transit = parseInt( rate.delivery.transit, 10 );

            if ( this.excluded_delivery_dates[ transit ].includes( value ) ) {
              return false;
            }

            const dateDiff = date.diff( now, 'days' ).days;

            // 1 = Monday, 7 = Sunday
            const dayThisShips = date.minus( { days: transit } ).weekday;

            // Valid rate if ship date is within transit time
            return dayThisShips < 4 && ( transit <= dateDiff || now.hour < 10 );
          } );
        } else {
          this.fulfillment.filtered = [];
        }

        // If the date changed and it doesn't include the rate previously picked, auto-pick the first one in the list
        if ( this.fulfillment.filtered.length && ! this.fulfillment.filtered.find( ( rate ) => rate.id === this.fulfillment.method ) ) {
          this.handleServiceSelection( this.fulfillment.filtered[0].id );
        }

        // Validate fields
        this.validateRequiredFields( 'shipping' );
      });

      this.$watch('shipping', () => {

        this.error = null;

        if ( this.step !== 'fulfillment' ) {
          return;
        }

        this.validateRequiredFields( 'shipping' );
      } );

      const self = this;

      // Trigger update order review if shipping fields change
      window.jQuery( 'form.woocommerce-checkout' ).on( 'input', [ '#shipping_address_1', 'address_2', 'state', 'city', 'country', 'postcode' ].join( ',#shipping_'), () => {

        this.validateRequiredFields( 'shipping' );

        self.catch_shipping_changes = true;

        if ( self.message ) {
          return;
        }

        if ( self.order_update_queue ) {
          clearInterval( self.order_update_queue as number );
        }

        self.order_update_queue = setInterval( function() {
          if ( ! self.order_updating ) {
            self.updateOrderReview( false, false )
            clearInterval( self.order_update_queue as number );
          }
        }, 5000 );
      } );

      window.jQuery( 'form.woocommerce-checkout' ).on( 'change', [ '#shipping_address_1', 'address_2', 'state', 'city', 'country', 'postcode' ].join( ',#shipping_'), () => {

        self.validateRequiredFields( 'shipping' );

        if ( ! self.initialized || ! self.catch_shipping_changes || self.message ) {
          return;
        }

        if ( self.order_update_queue ) {
          clearInterval( self.order_update_queue as number );
        }

        self.order_update_queue = setInterval( function() {
          if ( ! self.order_updating ) {
            self.updateOrderReview( false, false )
            clearInterval( self.order_update_queue as number );
          }
        }, 1000 );
      } );

      this.$watch('billing', () => {

        this.error = null;

        if ( this.step !== 'billing' ) {
          return;
        }

        this.validateRequiredFields( 'billing' );
      } );

      document.addEventListener('initializeAutocomplete', () => {
        this.$watch('shipping.address_1', () => {
          const autocomplete = new window.google.maps.places.Autocomplete(shippingAddressInput, {
            componentRestrictions: { country: ['us'] },
            types: ['geocode']
          });

          autocomplete.addListener('place_changed', () => {
            self.catch_shipping_changes = false;
            const place = autocomplete.getPlace();
            this.shipping = { ...this.shipping, ...parseAddress(place) };
            self.catch_shipping_changes = true;
            this.updateOrderReview();
          });
        });

        this.$watch('billing.address_1', () => {
          const autocomplete = new window.google.maps.places.Autocomplete(billingAddressInput, {
            componentRestrictions: { country: ['us'] },
            types: ['geocode']
          });

          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            this.billing = { ...this.billing, ...parseAddress(place) };
          });
        });
      });

      document.addEventListener( 'alpine:initialized', () => {
        this.updateOrderReview();
        this.initialized = true;
      } );
    },

    showScheduling() {
      if ( ! this.fulfillment.id ) return false;
      return this.step === 'fulfillment' && this.fulfillment.id === 'nationwide';
    },

    isOpen() {
      return this.now.hour >= storeHours[this.now.weekday - 1].opens + 1 && this.now.hour <= storeHours[this.now.weekday - 1].closes - 1;
    },

    isPickup() {
      if(!this.fulfillment.id) return false;
      return this.step === 'fulfillment' && this.fulfillment.id === 'pickup';
    },

    isDelivery() {
      if(!this.fulfillment.id) return false;
      return this.step === 'fulfillment' && this.fulfillment.id === 'delivery';
    },

    isNationwide() {
      if ( ! this.fulfillment.id ) return false;
      return this.step === 'fulfillment' && this.fulfillment.id === 'nationwide';
    },

    formatMoney(amount: number) {
      return formatMoney(amount);
    },

    formatAddress(address: Address) {
      if(!address.address_1) {
        return '';
      }

      const addressString = `${address.address_1}, ${address.city}, ${address.state} ${address.postcode}`;
      return addressString.trim();
    },

    validateOrder( step) {

      this.error = null;
      this.loading = true;

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'checkout_validate_order',
          step: this.step,
          step_to: step,
          fulfillment: this.fulfillment,
          billing: this.billing,
          shipping: this.shipping,
          shipping_method: [ this.fulfillment.method ],
          scheduling: this.scheduling
        },
        success: (response) => {

          this.loading = this.order_updating || false;

          if ( ! response.success ) {
            this.error = response.data.message;
            this.step = response.data.navigate_to || this.step;
            return;
          }

          this.step = step;
        },
      });
    },

    validateRequiredFields ( type: 'billing' | 'shipping' ) {

      let missingFields = [];

      if ( ! this[type].first_name ) {
        missingFields[ missingFields.length ] = 'first name';
      }
      if ( ! this[type].last_name ) {
        missingFields[ missingFields.length ] = 'last name';
      }
      if ( ! this[type].phone ) {
        missingFields[ missingFields.length ] = 'phone number';
      }
      if ( type === 'billing' && ! this[type].email ) {
        missingFields[ missingFields.length ] = 'email address';
      }

      const addressMap: Record<string, string> = {
        address_1: 'street address',
        city: 'city',
        state: 'state',
        postcode: 'ZIP code',
        country: 'country',
      };

      if ( this.fulfillment.id !== 'pickup' ) {

        const addressMapKeys = Object.keys( addressMap );
        // @ts-ignore
        const missingAddressFields = addressMapKeys.filter( ( field ) => ! this[type][field] );

        // Replace all the address fields with just "address" if it makes sense to do so.
        if ( missingAddressFields.length === addressMapKeys.length || ( missingAddressFields.length === ( addressMapKeys.length - 1 ) && ! missingAddressFields.includes( 'country' ) ) ) {
          missingFields[missingFields.length] = 'address';
        } else {
          missingFields = [ ...missingFields, ...missingAddressFields.map( ( field ) => addressMap[field] ) ];
        }
      }

      if ( type === 'shipping' ) {
        if ( this.fulfillment.id === 'pickup' && this.scheduling.type === 'later' && ! /^\d{2}-\d{2}-\d{4}$/.test( this.scheduling.date || '' ) ) {
          missingFields[ missingFields.length ] = 'pickup date';
        }

        if ( this.fulfillment.id !== 'pickup' && this.scheduling.type === 'later' && this.nationwide === 'yes' && this.fulfillment.rates.length ) {
          if (  ! /^\d{2}-\d{2}-\d{4}$/.test( this.scheduling.date || '' ) && ! this.error ) {
            missingFields[ missingFields.length ] = 'delivery date';
          }

          if ( this.fulfillment.id === 'nationwide' ) {
            if (  ! this.fulfillment.filtered.length && ! this.error ) {
              missingFields[ missingFields.length ] = 'delivery service';
            }
          }
        }
      }

      this.message = missingFields.length ? `<strong>To continue, please provide:</strong> ${ missingFields.length === 1 ? missingFields[0] : ( missingFields.slice(0,-1).join(', ') + ( missingFields.length === 2 ? '' : ',' ) + ' and ' + missingFields.slice(-1) ) }` : null;

    },

    getSelectedRate() {
      if ( ! this.fulfillment.method || this.fulfillment.rates.length === 0 ) {
        return null;
      }

      return this.fulfillment.rates.find((rate) => rate.id === this.fulfillment.method);
    },

    updateOrderTotals() {
      this.error = null;
      this.loading = true;

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'checkout_update_order_review',
          fulfillment: this.fulfillment,
          billing: this.billing,
          shipping: this.shipping,
          shipping_method: [ this.fulfillment.method ],
          scheduling: this.scheduling,
        },
        success: (response) => {
          this.loading = this.order_updating || false;

          if ( ! response.success ) {
            return;
          }

          this.totals = response.data.totals;
        },
      });
    },

    updateOrderReview( fulfillmentChanged: boolean = false, block: boolean = true ) {

      if ( this.order_updating ) {
        return;
      }

      this.order_updating = true;

      this.error = null;
      this.loading = true;
      this.address.result = '';

      if ( block ) {
        window.jQuery( '.e-checkout__column.e-checkout__column-start' ).block({
          message: null,
          overlayCSS: {
            background: '#fff',
            opacity: 0.6
          }
        } );
      }

      // Reset the totals and fees
      this.totals = {
        subtotal: this.totals.subtotal,
        shipping_total: null,
        fee_total: null,
        discount_total: null,
        total_tax: null,
        total: null,
      };
      this.fees = [];

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'checkout_update_order_review',
          fulfillment: this.fulfillment,
          scheduling: this.scheduling,
          billing: this.billing,
          shipping: this.fulfillment.id === 'pickup' ? {
            ...this.shipping,
            address_1: this.billing.address_1,
            address_2: this.billing.address_2,
            city: this.billing.city,
            postcode: this.billing.postcode,
            state: this.billing.state,
            country: 'US'
          } : this.shipping,
          shipping_method: [ this.fulfillment.method ],
        },
        success: (response) => {

          this.loading = false;

          // If erroneous, display error message.
          if ( ! response.success ) {
            this.error = response.data?.message || null;
            return;
          }

          this.fees = Object.values(response.data.fees).length ? Object.values(response.data.fees) : [];
          this.totals = response.data.totals;
          this.fulfillment.rates = response.data.rates[this.fulfillment.id] || [];
          this.available_rates = response.data.rates;
          this.fulfillment.method = response.data.method;

          this.fulfillment.location = response.data.location_id;

          this.excluded_delivery_dates = response.data.excluded_delivery_dates;

          if ( typeof window.wc_square_credit_card_payment_form_handler !== 'undefined' ) {
            window.wc_square_credit_card_payment_form_handler.location_id = response.data.location_id;
          }

          if ( Object.keys(response.data.errors ).length) {
            this.address.result = response.data.errors.delivery || '';
            this.error = Object.keys( response.data.errors ).filter( key => key !== 'delivery' ).map( key => response.data.errors[key] ).join( '<br />' );
          }

          if ( this.fulfillment.id !== 'nationwide' ) {
            if ( this.now.hour <= storeHours[this.now.weekday - 1].closes - 1 ) {
              this.scheduling.min = this.now.toFormat('MM-dd-yyyy');
            } else {
              this.scheduling.min = this.now.plus({ days: 1 }).toFormat('MM-dd-yyyy');
            }
          } else {
            this.scheduling.type = 'later';

            // If past Wednesday at 10am, set the minimum date to next week Tuesday
            if ( this.now.weekday > 3 || ( this.now.weekday === 3 && this.now.hour >= 10 ) ) {
              this.scheduling.min = this.now.plus({ days: 9 - this.now.weekday }).toFormat('MM-dd-yyyy');
            } else {
              if ( this.now.hour < 10 ) {
                // Can ship before 10am, so make minimum delivery date be tomorrow.
                this.scheduling.min = this.now.plus({ days: 1 }).toFormat('MM-dd-yyyy');
              } else {
                // Can't ship after 10am, so allow delivery the day after tomorrow.
                this.scheduling.min = this.now.plus({ days: 2 }).toFormat('MM-dd-yyyy');
              }
            }

            let disabled_dates: any = {};
            for ( const rate of this.fulfillment.rates ) {
              if ( ! rate.delivery?.hasOwnProperty( 'transit' ) || ! this.excluded_delivery_dates[ parseInt( rate.delivery.transit, 10 ) ] ) {
                disabled_dates = {};
                break;
              }
              this.excluded_delivery_dates[parseInt( rate.delivery.transit, 10 )].forEach( ( disabled_date ) => {
                disabled_dates[ disabled_date ] = ( typeof disabled_dates[ disabled_date ] === 'undefined' ) ? 1 : ( disabled_dates[ disabled_date ] + 1 );
              } );
            }

            for ( const disabled_date in disabled_dates ) {
              if ( disabled_dates[ disabled_date ] === this.fulfillment.rates.length ) {
                this.scheduling.disabled_dates.push( DateTime.fromFormat( disabled_date, 'MM-dd-yyyy', { zone: 'America/Chicago' } ).toJSDate() );
              }
            }
          }

          if ( this.fulfillment.id === 'delivery' ) {
            this.scheduling.max = this.now.plus({ days: 30 }).toFormat('MM-dd-yyyy');
          } else {
            this.scheduling.max = null;
          }

          this.recreateWooShippingMethodInputs();
          attachMethods();

          if ( this.fulfillment.id === 'pickup' || this.fulfillment.id === 'delivery' ) {

            if ( Array.isArray( this.excluded_delivery_dates[0] ) ) {
              this.scheduling.disabled_dates = this.excluded_delivery_dates[0].map( ( date_string ) => DateTime.fromFormat( date_string, 'MM-dd-yyyy', { zone: 'America/Chicago' } ).toJSDate() );
              if ( this.is_open ) {
                this.is_open =  ! this.excluded_delivery_dates[0].includes( this.now.toFormat( 'MM-dd-yyyy' ) );
              }
            }
          }

          if ( this.datepicker.instance ) {
            this.datepicker.instance.setOptions( {
              ...this.datepicker.options,
              daysOfWeekDisabled: this.fulfillment.id === 'nationwide' ? [0, 1, 6] : [],
              datesDisabled: this.scheduling.disabled_dates,
              minDate: this.scheduling.min,
              maxDate: this.scheduling.max
            } );
          }

          // Reset delivery date and time inputs if fulfillment was changed
          if ( fulfillmentChanged ) {
            this.scheduling = { ...this.scheduling, hour: 7, minute: 0, ampm: 'am' };
            this.scheduling.date = null;
          }

          this.$dispatch( 'fulfillment-method-changed', this.fulfillment.method );
        },
        complete: () => {
          this.validateRequiredFields( this.step === 'fulfillment' ? 'shipping' : 'billing' );
          this.order_updating = false;
          window.jQuery( '.e-checkout__column.e-checkout__column-start' ).unblock();
        }
      });
    },

    recreateWooShippingMethodInputs() {
      if ( Object.keys( this.available_rates ).length ) {

        if ( ! window.jQuery( 'ul#shipping_method' ).length ) {
          window.jQuery( 'table.shop_table.woocommerce-checkout-review-order-table tfoot' ).append( '<tr class=""><th>Shipping</th><td data-title="Shipping"><ul id="shipping_method" class="woocommerce-shipping-methods"></ul></td></tr>' );
        }

        const shippingMethodContainer = window.jQuery( 'ul#shipping_method' );
        shippingMethodContainer.empty();

        let index = 0;
        for ( const rateMethod of Object.keys( this.available_rates ) ) {

          for ( const rate of this.available_rates[rateMethod] ) {

            const eleId = 'shipping_method_0_' + rate.id.replace( /-/gi, '_' ).replace( /:/gi, '' );
            const radioElement = window.jQuery( '<input type="radio" name="shipping_method[0]" class="shipping_method" x-model.fill="fulfillment.method">' );
            radioElement.attr( 'data-index', index );
            radioElement.attr( 'id', eleId );
            radioElement.attr( 'value', rate.id );
            if ( this.fulfillment.method === rate.id ) {
              radioElement.prop( 'checked', true );
            }

            const labelElement = window.jQuery( '<label></label>' );
            labelElement.attr( 'for' , eleId );
            labelElement.text( rate.label );

            shippingMethodContainer.append( radioElement ).append( labelElement );

            index += 1;
          }
        }
      }
    },

    fetchFulfillmentRates() {
      this.error = null;
      this.loading = true;

      window.jQuery.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
          action: 'checkout_fetch_fulfillment_rates',
          fulfillment_id: this.fulfillment.id,
          shipping: this.shipping
        },
        success: (response) => {
          this.loading = false;

          if (!response.success) {
            return;
          }

          this.totals = response.data.totals;
          this.fulfillment.rates = response.data.rates[this.fulfillment.id] || [];
          this.fulfillment.method = response.data.method;

          this.$dispatch('fulfillment-method-changed', this.fulfillment.method);
        },
      });
    },

    handleStepSelection( step: string ) {

      this.catch_shipping_changes = false;
      clearTimeout( this.order_update_queue );

      this.validateOrder( step );
      this.updateOrderReview();

      if ( step === 'review' ) {
        window.jQuery( document.body ).trigger('update_checkout');
      }

      /* @TODO add order validation? */
    },

    handleFulfillmentSelection ( id: string ) {

      this.fulfillment.id = id;
      this.fulfillment.method = null;
      this.fulfillment.rates = [];
      this.scheduling.date = null;
      this.scheduling.disabled_dates = [];

      this.updateOrderReview( true );

      /* @TODO check to see if we have shipping information */

      /* @TODO get shipping rates */

      /* @TODO get checkout totals */
    },

    handleSchedulingTypeSelection ( type: string ) {
      this.scheduling.type = type;
    },

    handleSchedulingDateSelection() {},

    handleSchedulingTimeSelection() {},

    handleServiceSelection( method: string ) {
      this.fulfillment.method = method;
      this.updateOrderTotals();
    }
  }));


}

